import React, { useReducer, useState } from "react";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import {ModalErrors, handleErrorResponse, useRibbonSnackbar } from "@genrecp/g2clientportal-common";
import {LoadingButton as Button} from "@mui/lab"
import { RootState } from "../../../../redux/store";
import { authAxios, commonAxios } from "../../../../services/axios";
import axios from "axios";
import { UsersGrid } from "./UsersGrid";
import { CustomSnackbar } from "@genrecp/g2clientportal-common";
import { SimpleBackdrop, handleSuccessResponse,showInvalidSnackbar } from "@genrecp/g2clientportal-common";

import {
  ClientLocationArray,
  ApptypeTypeArray,
  getAppTypesBasedOnCLientLocationIDs,
  AppTypeRoles,
  UserTextFieldValidation,
  userReducer,
  initialUserState,
  exportInactiveExternalUsersToCSV,
} from "./userUtils";
import { CustomDrawer } from "./usersCustomDrawer/CustomDrawer";
import UserTextFieldComp from "./UserTextFieldComp";
import UserToggleComp from "./UserToggleComp";
import UserAutoCompleteComp from "./UserAutoCompleteComp";
import { RolesAcessLookUp, UserAppsLookup } from "../adminUtils";
import UsersGridWrapper from "./UsersGridWrapper";
import isEmail from "validator/lib/isEmail";

function Users() {
  const { enqueueSnackbar } = useRibbonSnackbar();
  const isExternal = useSelector(
    (state: RootState) => state?.auth?.currentUser?.isExternal
  );
  const isExternalUser = (isExternal && JSON.parse(isExternal.toLowerCase()));

  // ROLES FORMAT CHANGE
  const userRole = useSelector(
    (state: RootState) => state?.auth?.currentUser?.appRolePermissions
  );
  const permissions = JSON.parse(userRole!);

  const createdBy = useSelector((state: RootState) => state?.auth?.currentUser);

  const [appTypeArray, setAppTypeArray] = React.useState<ApptypeTypeArray[]>(
    []
  );
  const [intExtToggler, setintExtToggler] = React.useState<boolean>(false);

  const [clientLocationInputValue, setClientLocationInputValue] =
    React.useState("");

  const [clientLocationArray, setclientLocationArray] = React.useState<
    ClientLocationArray[]
  >([]);

  const [userRequest, dispatch] = useReducer(
    userReducer,
    initialUserState
  ); 

  const [isEmailValid, setIsEmailValid] = useState(false);

  // specific case states
  const [caseWhenOnlyOneClientLocation, setCaseWhenOnlyOneClientLocation] =
    useState(false);
  const [appTypeLoadingSpecificCase, setappTypeLoadingSpecificCase] =
    useState(false);

  // validations/
  const textFieldValidations =
    userRequest.userLoginID &&
    // userRequest.businessPhone &&
    userRequest.emailAddress &&
    userRequest.firstName &&
    userRequest.lastName;
  const dropDownsValidations =
    userRequest.userClientLocations &&
    userRequest.userClientLocations?.length > 0 &&
    userRequest.userAppTypes &&
    userRequest.userAppTypes?.length > 0;

  const [textFieldValiations,setTextFieldValidations] = React.useState<UserTextFieldValidation>({
    fName:false,
    lName:false
  }) 
  const [externalUserLoginIDValidation, setexternalUserLoginIDValidation] =
    React.useState(false);
    const [userLoginIDFormatValidation, setUserLoginIDFormatValidation] =
    React.useState(false);
  const [internalUserLoginIDValidation, setinternalUserLoginIDValidation] =
    React.useState(false);
  const [hideExternalForSystenAdmin, setHideExternalForSystenAdmin] =
    React.useState(false);
  const [emailDomainValidation, setEmailDomainValidation] =
    React.useState(false);
  const [emailDomainValidationProcessing, setEmailDomainValidationProcessing] =
    React.useState(false); // HANDLES EMAIL DOMAIN VALIDATION LOADER
  const [submitUpdateCancelHandler, setSubmitUpdateCancelHandler] =
    React.useState(false); // HANDLES CANCEL BUTTON API CALL TO LOAD INITAL STATE
  const [tempAppRoleData, setTempAppRoleData] = React.useState<AppTypeRoles[]>(
    []
  ); // TEMP APP ROLE DATA

  const [modalErrors, setModalErrors] = React.useState<ModalErrors>({
    errors: {messages:[]},
    ErrorModelOpen: false,
  });

  // internal case if external false assign all location and disable
  const [internalCase, setInternalCase] = React.useState(false);
  const [apiLoading, setApiLoading] = React.useState(false);
  const [reportLoading, setReportLoading] = React.useState(false);

  const [refreshToggler, setrefreshToggler] = React.useState(false);

  const [updatetoggler, setUpdatetoggler] = React.useState(false);

  const [allowedOAuthDomainsCheck, setAllowedOAuthDomainsCheck] = useState<boolean>(false);
  const [allowedOAuthDomainsCheckProcessing, setAllowedOAuthDomainsCheckProcessing] =
  React.useState(false);
  const oAuthErrorMessage =
            "Error! The user provided is not validated as approved tenant domain user. Please verify or contact admin support.";

  let handler:any;
  function checkDebouncedOAuthDomains(userLoginID: string){
    setAllowedOAuthDomainsCheckProcessing(true);
    authAxios
      .get(`/GetUserLoginIDDomainsForOAuth?userLoginID=${userLoginID}`)
      .then((res: any) => {
        setAllowedOAuthDomainsCheckProcessing(false);
        const { status, data } = res || {};
        if (status === 200) {
          setAllowedOAuthDomainsCheck(!data);
          
          !data &&
            enqueueSnackbar(oAuthErrorMessage, {
              variant: "error",
              errorObject: {
                messages: [oAuthErrorMessage],
              },
            });
        } else {
          showInvalidSnackbar(null, enqueueSnackbar);
        }
      })
      .catch((e: any) => {
        setAllowedOAuthDomainsCheckProcessing(false);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
      clearTimeout(handler);
  }
 
  function getUserLoginIDDomainsForOAuth(userLoginID: string) {
    handler = setTimeout(() => {
      checkDebouncedOAuthDomains(userLoginID);
    }, 1000); // 300ms debounce time
  }

  const emailDomainValidator = (value: string | undefined) => {
    if (!value) return;

    const params = {
      emailAddress: value,
      clientLocationIds: userRequest?.userClientLocations?.map(
        (item: any) => item.clientLocationID
      ),
    };

    // validate if the domain exists
    authAxios
      .post("/ValidateEmailForClientLocationGroupDomain", params)
      .then((res: any) => {
        setEmailDomainValidationProcessing(false);
        if (res.data) {
          setEmailDomainValidation(false);
        } else {
          setEmailDomainValidation(true);
        }
      })
      .catch((e: any) => {
        setEmailDomainValidationProcessing(false);
        enqueueSnackbar(`Error! Invalid Domain name`, {
          variant: "error",
        });
      });
  };

  function checkEmailFormat(value:string){
    if(isEmail(value)) {
      setUserLoginIDFormatValidation(false);
    } else {
      setUserLoginIDFormatValidation(true);
    }
  }

  const userLoginIDValidator = () => {
    setAllowedOAuthDomainsCheck(false);
    if (!userRequest.userLoginID) {
      setUserLoginIDFormatValidation(false);
      setexternalUserLoginIDValidation(false);
      setinternalUserLoginIDValidation(false);
      return;
    }

    if (userRequest.isManagedByG2 && userRequest.external) {
      setinternalUserLoginIDValidation(false);

      const pattern = /.+@genstarinsext.com$/gi;
      var match = pattern.test(userRequest.userLoginID);

      if (match) {
          setexternalUserLoginIDValidation(false);
      } else {
          setexternalUserLoginIDValidation(true);
      }
        
      checkEmailFormat(userRequest.userLoginID);
      dispatch({userLoginID: userRequest.userLoginID });
    } else if (!userRequest.isManagedByG2 && !userRequest.external) {
      setexternalUserLoginIDValidation(false);
      const exPattern = /.+@generalstar.com$/gi;
      const exPattern2 = /.+@genre.com$/gi;
      const exPattern3 = /.+@gumc.com$/gi;

      var exMatch = exPattern.test(userRequest.userLoginID);
      var exMatch2 = exPattern2.test(userRequest.userLoginID);
      var exMatch3 = exPattern3.test(userRequest.userLoginID);

      if (exMatch 
        || exMatch2
        || exMatch3
      ) {
        setinternalUserLoginIDValidation(false);
      } else {
        setinternalUserLoginIDValidation(true);
      }

      checkEmailFormat(userRequest.userLoginID);
    }
    //  else if (!userRequest.isManagedByG2 && userRequest.external && isEmail(userRequest.userLoginID)) {
    //   setexternalUserLoginIDValidation(false);
    //   setinternalUserLoginIDValidation(false);
    //   setUserLoginIDFormatValidation(false);
    //   getUserLoginIDDomainsForOAuth(userRequest.userLoginID);
    // }
     else {
      setAllowedOAuthDomainsCheck(false);
      setexternalUserLoginIDValidation(false);
      setinternalUserLoginIDValidation(false);
      checkEmailFormat(userRequest.userLoginID);
    }
  };

  const handleSubmit = () => {
    // if (PhoneNumberValidation) return;
    if (emailDomainValidation) return;
    if (userLoginIDFormatValidation) return;
    if (externalUserLoginIDValidation) return;
    if (internalUserLoginIDValidation) return;
    if (allowedOAuthDomainsCheck) {
      enqueueSnackbar(oAuthErrorMessage, {
        variant: "error",
        errorObject: {
          messages: [oAuthErrorMessage],
        },
      });
      return;
    }
    if(textFieldValiations.fName || textFieldValiations.lName) return;

    if (!dropDownsValidations) {
      enqueueSnackbar(`Error! All fields are required`, {
        variant: "error",
      });
      return;
    }

    if (!textFieldValidations || userRequest.appTypeRoles.length === 0) {
      enqueueSnackbar(`Error! All fields are required`, {
        variant: "error",
      });
      return;
    }
    if (isEmailValid) {
      enqueueSnackbar(`Error! Email is invalid`, {
        variant: "error",
      });
      return;
    }
    if (!createdBy) return;

    const modifiedUserClientLocations = userRequest.userClientLocations.map((item:any) => ({
      ...item,
      createdBy : createdBy.id,
      createdDate : new Date().toISOString()
    }));

    const modifiedAppTypeRoles = userRequest.appTypeRoles.map((itemx:any) => ({
      ...itemx,
      createdBy : createdBy.id,
      createdDate : new Date().toISOString()
    }));

    const params = {
      ...userRequest,
      userClientLocations:modifiedUserClientLocations,
      appTypeRoles:modifiedAppTypeRoles,
      managerID: createdBy.id,
      createdBy: createdBy.id,
      createdDate: new Date().toISOString(),
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {userID,modifiedBy,modifiedDate,openCustomDrawer,userAppTypes,...restOfParams} = params;

    setApiLoading(true);

    authAxios
      .post("/User", restOfParams)
      .then((res: any) => {
        // HANDLING SUCCESS MESSAGE IN NOTISTACK
        handleSuccessResponse(res, enqueueSnackbar);

        setrefreshToggler(!refreshToggler);
        dispatch({
          userLoginID: "",
          firstName: "",
          lastName: "",
          emailAddress: "",
          businessPhone: "",
          appTypeRoles: [],
          userClientLocations: [],
          userAppTypes: [],
          isManagedByG2: true,
        })
        setSubmitUpdateCancelHandler(!submitUpdateCancelHandler); // AFTER SUBMITTING RELOAD INITIAL STATE API
        setApiLoading(false);
      })
      .catch((e: any) => {
        setApiLoading(false);
        // SHOWS ACTUAL ERROR IN CASE OF 400 OTHERWISE THROWS INVALID REQUEST ERROR
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  };

  function handleUpdate(appTypeRoleParams:undefined | AppTypeRoles[] = undefined){
    // if (PhoneNumberValidation) return;
    if (emailDomainValidation) return;
    if (userLoginIDFormatValidation) return;
    if (externalUserLoginIDValidation) return;
    if (internalUserLoginIDValidation) return;
    if (allowedOAuthDomainsCheck) {
      enqueueSnackbar(oAuthErrorMessage, {
        variant: "error",
        errorObject: {
          messages: [oAuthErrorMessage],
        },
      });
      return;
    }
    if(textFieldValiations.fName || textFieldValiations.lName) return;

    if (!dropDownsValidations) {
      enqueueSnackbar(`Error! All fields are required`, {
        variant: "error",
      });
      return;
    }

    if (!textFieldValidations || userRequest.appTypeRoles.length === 0) {
      enqueueSnackbar(`Error! All fields are required`, {
        variant: "error",
      });
      return;
    }

    if (isEmailValid) {
      enqueueSnackbar(`Error! Email is invalid`, {
        variant: "error",
      });
      return;
    }
    if (!createdBy) return;

    const modifiedUserClientLocations = userRequest.userClientLocations.map((item:any) => ({
      ...item,
      createdBy : createdBy.id,
      createdDate : new Date().toISOString()
    }));

    const modifiedAppTypeRoles = (appTypeRoleParams ? appTypeRoleParams : userRequest.appTypeRoles).map((itemx:any) => ({
      ...itemx,
      createdBy : createdBy.id,
      createdDate : new Date().toISOString()
    }));

    const params = {
      ...userRequest,
      userClientLocations:modifiedUserClientLocations,
      appTypeRoles:modifiedAppTypeRoles,
      modifiedBy: createdBy.id,
      modifiedDate: new Date().toISOString(),
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {openCustomDrawer,userAppTypes,...restOfParams} = params;

    setApiLoading(true);

    authAxios
      .put(`/User?id=${userRequest.userID}`, restOfParams)
      .then((res: any) => {
        // HANDLING SUCCESS MESSAGE IN NOTISTACK
        handleSuccessResponse(res, enqueueSnackbar);

        setUpdatetoggler(false);
        setrefreshToggler(!refreshToggler);
        dispatch({
          userLoginID: "",
          firstName: "",
          lastName: "",
          emailAddress: "",
          businessPhone: "",
          appTypeRoles: [],
          userClientLocations: [],
          userAppTypes: [],
          isManagedByG2: true,
          openCustomDrawer:false
        });

        setSubmitUpdateCancelHandler(!submitUpdateCancelHandler); // AFTER UPDATING RELOAD INITIAL STATE API
        setApiLoading(false);
      })
      .catch((e: any) => {
        setApiLoading(false);
        // SHOWS ACTUAL ERROR IN CASE OF 400 OTHERWISE THROWS INVALID REQUEST ERROR
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  };

  React.useEffect(() => {
    sessionStorage.removeItem("appTypeRoleSelection");
    if (isExternalUser) {
      setApiLoading(true);
      authAxios.get("/CurrentUserClientLocations")
        .then((res: any) => {
            const incomingAssignedLocations = res?.data || {};

            setApiLoading(false);

            if (incomingAssignedLocations) {
              let permittedValues = [];
              if(!permissions) return;
                const userAdministrationRole = permissions.find(
                  (item: any) => item.appTypeId === UserAppsLookup.UserAdministration
                );
                const tenantAdministratorRole = userAdministrationRole.roleId === RolesAcessLookUp.TenantAdministrator;
                const clientLocationAdministratorRole = userAdministrationRole.roleId === RolesAcessLookUp.ClientLocationAdministrator;
                /**
                 * Client location administrator can be assigned only app
                */
                if (
                  clientLocationAdministratorRole &&
                  incomingAssignedLocations.length === 1
                ) {
                  dispatch({
                    userClientLocations: incomingAssignedLocations,
                  });
                  /**
                   * Disabling clientlocation drop down in case of one value
                   * and setting its value
                  */
                  setCaseWhenOnlyOneClientLocation(true);
                  permittedValues = incomingAssignedLocations.map((value: any) => ({
                    clientLocationID: value.clientLocationID,
                    clientLocationUniqueName: value.clientLocationUniqueName,
                  }));
                  setclientLocationArray(permittedValues);
                  /**
                   * Getting app types depending upon this
                  */
                  getAppTypesBasedOnCLientLocationIDs(
                    permittedValues,
                    setappTypeLoadingSpecificCase,
                    setAppTypeArray,
                    enqueueSnackbar,
                    setTempAppRoleData,
                    userRequest,
                    dispatch
                  );
                } else if (
                  tenantAdministratorRole &&
                  incomingAssignedLocations.length > 0
                ) {
                  /**
                   * In case of multiple client location
                  */                  
                  permittedValues = incomingAssignedLocations.map((value: any) => ({
                    clientLocationID: value.clientLocationID,
                    clientLocationUniqueName: value.clientLocationUniqueName,
                  }));
                  setclientLocationArray(permittedValues);
                } else return;

            }
          }
        )
        .catch((e: any) => {
          // DISABLE LOADER
          setApiLoading(false);
          console.log(e.message);
          showInvalidSnackbar(e,enqueueSnackbar);
        });
    } else {
      setApiLoading(true);
      // default case internal user
      const getAppTypes = authAxios.get("/AppTypesWithRoles");
      const getLocation = commonAxios.get("/ClientLocations");

      axios
        .all([getAppTypes, getLocation])
        .then(
          axios.spread((...responses: any) => {
            const incomingAppTypes = responses[0]?.data || {};
            const incomingAssignedLocations = responses[1]?.data || {};

            setApiLoading(false);

            // getting apptypes
            if (incomingAppTypes) {
              let permittedValues = incomingAppTypes.map((value: any) => ({
                appTypeID: value.appTypeID,
                appTypeText: value.appTypeText,
              }));
              setAppTypeArray(permittedValues);
              setTempAppRoleData(incomingAppTypes);
            }

            if (incomingAssignedLocations) {
              /**
               * Internal case if external false assign all location and disable
              */ 
              if (userRequest.external) {
                // if true hide all option
                setInternalCase(false);
                setintExtToggler(!intExtToggler);

                let permittedValues = incomingAssignedLocations.map((value: any) => ({
                  clientLocationID: value.clientLocationID,
                  clientLocationUniqueName: value.clientLocationUniqueName,
                }));
                setclientLocationArray(permittedValues);
              } else {
                setclientLocationArray([
                  {
                    clientLocationID: "0000",
                    clientLocationUniqueName: "All",
                  },
                ]);
                setInternalCase(true);
                setintExtToggler(!intExtToggler);
              }
            }
          })
        )
        .catch((e: any) => {
          // DISABLE LOADER
          setApiLoading(false);
          const modalErrorMessages = handleErrorResponse(e);
          setModalErrors({
            ...modalErrors,
            errors: modalErrorMessages,
            ErrorModelOpen: true,
          });
        });
    }
    return () => {
      setApiLoading(false);
      setAppTypeArray([]);
      setclientLocationArray([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitUpdateCancelHandler]);

  React.useEffect(() => {
    if (internalCase) {
      dispatch({
        userClientLocations: [
          {
            clientLocationID: "0000",
            clientLocationUniqueName: "All",
          },
        ],
        isManagedByG2: false,
        userAppTypes: [],
      });
      setCaseWhenOnlyOneClientLocation(true);
      setClientLocationInputValue("All");
      // setclientLocationArray([]);
      setEmailDomainValidation(false);
    } else {
      emailDomainValidator(userRequest?.emailAddress);
      // external case on toggle
      setClientLocationInputValue("");
      dispatch({
        userClientLocations: [],
        userAppTypes: [],
        isManagedByG2: true,
      });
      setCaseWhenOnlyOneClientLocation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalCase, intExtToggler]);
  
  React.useEffect(()=>{
    userLoginIDValidator();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[userRequest.isManagedByG2,userRequest.external,userRequest.userLoginID]);

  const generateLastLoginReport = () => {
    setReportLoading(true);
      authAxios.get("/GetInactiveExternalUsers")
        .then((res: any) => {
          setReportLoading(false);
          const {data} = res || {};
          if(data){
            exportInactiveExternalUsersToCSV(data,enqueueSnackbar);
          } else {
            showInvalidSnackbar(null,enqueueSnackbar);
          }
        })
        .catch((e: any) => {
          // DISABLE LOADER
          setReportLoading(false);
          const modalErrorMessages = handleErrorResponse(e);
          setModalErrors({
            ...modalErrors,
            errors: modalErrorMessages,
            ErrorModelOpen: true,
          });
        });
  }

  const onLoginIDBlur = (userLoginID:string) => {
    if (!userRequest.isManagedByG2 && userRequest.external && isEmail(userLoginID)) {
      setexternalUserLoginIDValidation(false);
      setinternalUserLoginIDValidation(false);
      setUserLoginIDFormatValidation(false);
      getUserLoginIDDomainsForOAuth(userLoginID);
    }
  }

  React.useEffect(() => {
    !userRequest.isManagedByG2 && onLoginIDBlur(userRequest.userLoginID || "")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[userRequest.isManagedByG2])

  return (
    <>
      <Grid container maxWidth="lg">
        <CustomSnackbar request={modalErrors} setRequest={setModalErrors} />
        <SimpleBackdrop open={apiLoading} />
        <CustomDrawer
          request={userRequest}
          tempAppRoleData={tempAppRoleData}
          updatetoggler={updatetoggler}
          appTypeArray={appTypeArray}
          handleUpdate={handleUpdate}
          dispatch={dispatch}
        />
        <Grid xs={12} sm={12} md={12} item display={"flex"} justifyContent={"space-between"}> 
          <Typography variant="displaySmall">
            User Administration
          </Typography>
          <Button 
            variant="containedTertiary"
            onClick={generateLastLoginReport}
            loading={reportLoading}
            disabled={reportLoading}
           >Download Last Login Report</Button>
        </Grid>
      </Grid>

      {/**
       * caseWhenOnlyOneClientLocation: Specific case for One Client Location
       * userRequest: Form Data
       * dispatch: Sets Form Data
       * clientLocationInputValue: ClientLocation Free text
       * setClientLocationInputValue: Sets ClientLocation Free text
       * clientLocationArray: List Data for client Location
       * setAppTypeArray: Sets List Data for AppType
       * setTempAppRoleData: Sets Temporary App Type data. Used for comparison
       *  */}      

      <UserAutoCompleteComp 
            caseWhenOnlyOneClientLocation={caseWhenOnlyOneClientLocation}
            userRequest={userRequest}
            clientLocationInputValue={clientLocationInputValue}
            setClientLocationInputValue={setClientLocationInputValue}
            clientLocationArray={clientLocationArray}
            setappTypeLoadingSpecificCase={setappTypeLoadingSpecificCase}
            setAppTypeArray={setAppTypeArray}
            setTempAppRoleData={setTempAppRoleData}
            appTypeLoadingSpecificCase={appTypeLoadingSpecificCase}
            dispatch={dispatch}
      />

      {/**
       * userRequest: Form Data
       * internalUserLoginIDValidation: To show UserLoginID Errors in case of internal User
       * externalUserLoginIDValidation: To show UserLoginID Errors in case of external User
       * internalCase: In case of external assigning all location. checks for internal or external User
       * isEmailValid && setIsEmailValid: To show errors in case of invalid Email
       * emailDomainValidationProcessing && setEmailDomainValidationProcessing: Loader in case of Domian Api Validation
       * emailDomainValidator: Email Domain validation function.Validates Domain through Api call
       * emailDomainValidation && setEmailDomainValidation: To show errors in case of domain not being allowed
       * updatetoggler: To check whether the scenario is update or submit
       * setTextFieldValidations: no spaces allowed
       *  */}

      <UserTextFieldComp
        userRequest={userRequest}
        internalUserLoginIDValidation={internalUserLoginIDValidation}
        externalUserLoginIDValidation={externalUserLoginIDValidation}
        userLoginIDFormatValidation={userLoginIDFormatValidation}
        internalCase={internalCase}
        isEmailValid={isEmailValid}
        setIsEmailValid={setIsEmailValid}
        emailDomainValidationProcessing={emailDomainValidationProcessing}
        setEmailDomainValidationProcessing={setEmailDomainValidationProcessing}
        emailDomainValidator={emailDomainValidator}
        emailDomainValidation={emailDomainValidation}
        setEmailDomainValidation={setEmailDomainValidation}
        updatetoggler={updatetoggler}
        setTextFieldValidations={setTextFieldValidations}
        textFieldValiations={textFieldValiations}
        dispatch={dispatch}
        allowedOAuthDomainsCheckProcessing={allowedOAuthDomainsCheckProcessing}
        onLoginIDBlur={onLoginIDBlur}
      />

      {/*******************************************************************/}

      {/**
       * hideExternalForSystenAdmin: Condtion to check if user is external and tenant OR client location admin
       * updatetoggler: To check whether the scenario is update or submit
       * userRequest: Form Data
       * dispatch: Set Form Data
       * internalCase: In case of external assigning all location. checks for internal or external User
       * setInternalCase: Sets whether User is internal or external
       * submitUpdateCancelHandler: For Loading initial data
       * setSubmitUpdateCancelHandler: For setting submitUpdateCancelHandler
       * setUpdatetoggler: To check whether the scenario is update or submit. Sets updatetoggler
       * setinternalUserLoginIDValidation: To show UserLoginID Errors in case of internal User
       * setexternalUserLoginIDValidation: To show UserLoginID Errors in case of external User
       * handleSubmit: Submit Scenario
       * handleUpdate: Update Scenario
       * setTextFieldValidations: no spaces allowed
       * */}

      <UserToggleComp
        hideExternalForSystenAdmin={hideExternalForSystenAdmin}
        updatetoggler={updatetoggler}
        userRequest={userRequest}
        internalCase={internalCase}
        setInternalCase={setInternalCase}
        submitUpdateCancelHandler={submitUpdateCancelHandler}
        setSubmitUpdateCancelHandler={setSubmitUpdateCancelHandler}
        setUpdatetoggler={setUpdatetoggler}
        setinternalUserLoginIDValidation={setinternalUserLoginIDValidation}
        setexternalUserLoginIDValidation={setexternalUserLoginIDValidation}
        setUserLoginIDFormatValidation={setUserLoginIDFormatValidation}
        handleSubmit={handleSubmit}
        handleUpdate={handleUpdate}
        setTextFieldValidations={setTextFieldValidations}
        dispatch={dispatch}
        setAllowedOAuthDomainsCheck={setAllowedOAuthDomainsCheck}
      />

      {/*******************************************************************/}

      <Grid container spacing={2} mt={1}>
        <Grid xs={12} sm={12} md={12} item>
          <UsersGridWrapper 
            clientLocationArray={clientLocationArray}
          >
            <UsersGrid
              request={userRequest}
              setUpdatetoggler={setUpdatetoggler}
              setIsEmailValid={setIsEmailValid}
              setEmailDomainValidation={setEmailDomainValidation}
              refreshToggler={refreshToggler}
              setclientLocationArray={setclientLocationArray}
              appTypeArray={appTypeArray}
              setHideExternalForSystenAdmin={setHideExternalForSystenAdmin}
              setAppTypeArray={setAppTypeArray}
              setappTypeLoadingSpecificCase={setappTypeLoadingSpecificCase}
              setSubmitUpdateCancelHandler={setSubmitUpdateCancelHandler}
              submitUpdateCancelHandler={submitUpdateCancelHandler}
              setTempAppRoleData={setTempAppRoleData}
              setApiLoading={setApiLoading}
              dispatch={dispatch}
            />
         </UsersGridWrapper>
        </Grid>
      </Grid>
    </>
  );
}

export default Users;
