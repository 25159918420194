import React from "react";
import { AggridWrapper } from "@genre/common-wrapper-aggrid";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridClasses } from "@genre/g2common-theme";
import {
  comparatorForDate,
  CustomSnackbar,
  formatDateForGrid,
  handleErrorResponse,
  ModalErrors,
  showInvalidSnackbar,
  useRibbonSnackbar,
  ViewSchema,
} from "@genrecp/g2clientportal-common";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { DelegatedIssuesProps } from "./utils";
import { RootState } from "../../../redux/store";
import { delegatedAxios } from "../../../services/axios";
import { SetFilterValuesFuncParams, ISetFilterParams } from "ag-grid-community";

interface Props extends DelegatedIssuesProps {}

interface DelegatedPolicyIssueTypes {
  issueTypeID: number;
  issueTypeIDIdentifierText: string;
}

function PolicyEndorsementGrid({ state, setState }: Props) {
  const [modalErrors, setModalErrors] = React.useState<ModalErrors>({
    errors: { messages: [] },
    ErrorModelOpen: false,
  });
  const createdBy = useSelector((state: RootState) => state?.auth?.currentUser);
  const [delegatedPolicyIssueTypesList, setDelegatedPolicyIssueTypesList] =
    React.useState<DelegatedPolicyIssueTypes[]>([]);

  const delegatedPolicyIssueTypesListRef =
    React.useRef<DelegatedPolicyIssueTypes[]>();

  delegatedPolicyIssueTypesListRef.current = delegatedPolicyIssueTypesList;

  const { enqueueSnackbar } = useRibbonSnackbar();

  const getGridRowsData = (
    pagination: Object,
    filterModel: Object,
    sortModel: Array<Object>,
    selectedView: ViewSchema | undefined
  ) => {
    const newObj: any = { ...filterModel };
    const obj: any = {
      ...pagination,
      ...newObj,
      orderBy: sortModel
        .map((model: any) => model.colId + " " + model.sort)
        .join(", "),
    };
    if (obj.orderBy === "") delete obj.orderBy;

    if (newObj.issueTypeIDIdentifierText) {
      const tempStatus = obj.issueTypeIDIdentifierText
        .split(",")
        .map((item: string) => delegatedPolicyIssueTypesParser(item.trim()))
        .filter((item: number | undefined) => item !== undefined);
      obj.issueTypeIDs = tempStatus;
      delete obj.issueTypeIDIdentifierText;
    }

    return delegatedAxios
      .post(`/Issues/GetDelegatedPolicyIssues`, obj)
      .then((data: any) => {
        return data.data;
      })
      .catch((e) => {
        console.log(e);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
        return [];
      });
  };

  function getColumnDefs(view: ViewSchema | undefined) {
    return [
      {
        colId: "policyNumber",
        field: "policyNumber",
        headerName: "Policy Number",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
      },
      {
        colId: "endtNumber",
        field: "endtNumber",
        headerName: "Endorsement Number",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
      },
      {
        field: "effectiveDate",
        filter: "agDateColumnFilter",
        valueGetter: function (params: any) {
          return formatDateForGrid(params.data.effectiveDate);
        },
        filterParams: {
          filterOptions: ["equals"],
          comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
            return comparatorForDate(
              filterLocalDateAtMidnight,
              cellValue,
              true
            );
          },
          suppressAndOrCondition: true,
        },
      },
      {
        field: "expirationDate",
        filter: "agDateColumnFilter",
        valueGetter: function (params: any) {
          return formatDateForGrid(params.data.expirationDate);
        },
        filterParams: {
          filterOptions: ["equals"],
          comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
            return comparatorForDate(
              filterLocalDateAtMidnight,
              cellValue,
              true
            );
          },
          suppressAndOrCondition: true,
        },
      },
      {
        colId: "namedInsured",
        field: "namedInsured",
        headerName: "Insured Name",
        filter: "agTextColumnFilter",
        tooltipField: "namedInsured",
        width: 320,
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
      },
      {
        colId: "issueTypeIDIdentifierText",
        field: "issueTypeIDIdentifierText",
        headerName: "Issue Type",
        filter: "agSetColumnFilter",
        tooltipField: "issueTypeIDIdentifierText",
        width: 320,
        filterParams: {
          buttons: ["reset"],
          suppressSelectAll: true,
          defaultToNothingSelected: true,
          filterOptions: ["equals"],
          values: async (params: SetFilterValuesFuncParams) => {
            let values = await getStatusFilters();
            params.success(values);
          },
          showTooltips: true,
          suppressAndOrCondition: true,
        } as ISetFilterParams,
      },

      {
        colId: "issueDescription",
        field: "issueDescription",
        tooltipField: "issueDescription",
        headerName: "Issue Description",
        filter: "agTextColumnFilter",
        width: 420,
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
      },

      {
        colId: "comments",
        field: "comments",
        tooltipField: "comments",
        width: 320,
        headerName: "Client Comments",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
      },
      {
        colId: "clientName",
        field: "clientName",
        headerName: "Client Name",
        tooltipField: "clientName",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
        width: 320,
      },
      {
        colId: "clientCity",
        field: "clientCity",
        headerName: "Client City",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
        width: 190,
      },
      {
        colId: "clientState",
        field: "clientState",
        headerName: "Client State",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
        width: 190,
      },
    ];
  }

  function delegatedPolicyIssueTypesParser(payload: string) {
    if (
      !delegatedPolicyIssueTypesListRef.current ||
      !delegatedPolicyIssueTypesListRef.current.length
    ) {
      showInvalidSnackbar(null, enqueueSnackbar);
      return;
    }
    const finalCashTrackerStatus: number | undefined =
      delegatedPolicyIssueTypesListRef.current.find(
        (item) => item.issueTypeIDIdentifierText.trim() === payload
      )?.issueTypeID || undefined;
    return finalCashTrackerStatus;
  }

  async function getStatusFilters() {
    return delegatedAxios
      .get("/Issues/GetIssueTypesForDelegatedPolicy")
      .then((res: any) => {
        const { data } = res || {};
        setDelegatedPolicyIssueTypesList(data || []);
        return data
          ? data.map(
              (item: DelegatedPolicyIssueTypes) =>
                item.issueTypeIDIdentifierText
            )
          : [];
      })
      .catch((e) => {
        console.log(e);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
        return [];
      });
  }

  return (
    <Grid md={12} item>
      <CustomSnackbar request={modalErrors} setRequest={setModalErrors} />
      <AggridWrapper
        gridClassName={`ag-theme-alpine ${AgGridClasses["ag-theme-alpine"]}`}
        getColumnDefs={getColumnDefs}
        getGridRowsData={getGridRowsData}
        dashboardName={""}
        enableUserViews={false}
        enableSystemViews={false}
        landingPage={"Suspense"}
        views={false}
        currentUser={{
          userName: createdBy && createdBy.id ? createdBy.id : "",
          email: "",
          fullName: "",
        }}
        gridProps={{
          headerHeight: 40,
          tooltipMouseTrack: true,
          tooltipShowDelay: 0,
        }}
        hideHeader
        paginationPageSize={50}
        rowHeight={29}
        headerHeight={32}
        gridHeight={window.innerHeight - 190}
        showSidebar
      ></AggridWrapper>
    </Grid>
  );
}

export default PolicyEndorsementGrid;
