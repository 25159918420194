import React, { Fragment } from "react";
import { ActionBar } from "@genrecp/g2clientportal-common";
import GridToggler from "../components/GridToggler";
import { DelegatedIssuesState } from "../utils";
import DelegatedIssuesInfoSectionSelector from "./DelegatedIssuesInfoSectionSelector";

function DelegatedPolicyIssues() {
  const [state, setState] = React.useState<DelegatedIssuesState>({
    screenName: "POLICY_ENDORSEMENT",
  });

  return (
    <Fragment>
      <ActionBar menuItems={[]} dashboardName={""}>
        <GridToggler state={state} setState={setState} />
      </ActionBar>
      <DelegatedIssuesInfoSectionSelector state={state} setState={setState} />
    </Fragment>
  );
}

export default DelegatedPolicyIssues;
