import React from "react";
import { UserRequestObject, UserDispatch, UserTextFieldValidation } from "./userUtils";
import isEmail from "validator/lib/isEmail";
import { TextInput } from "@genrecp/g2clientportal-common";
import { Spinner } from "@genrecp/g2clientportal-common";
import { Grid } from "@mui/material";
import { LoginIDAppendButton } from "./LoginIDAppendButton";

interface Props {
  userRequest: UserRequestObject;
  internalCase: boolean;

  isEmailValid: boolean;
  setIsEmailValid: (v: boolean) => void;

  setEmailDomainValidationProcessing: (v: boolean) => void;
  emailDomainValidationProcessing: boolean;

  emailDomainValidator: (v: string | undefined) => void;

  emailDomainValidation: boolean;
  setEmailDomainValidation: (v: boolean) => void;

  internalUserLoginIDValidation: boolean;
  externalUserLoginIDValidation: boolean;
  userLoginIDFormatValidation: boolean;

  updatetoggler: boolean;

  textFieldValiations: UserTextFieldValidation;
  setTextFieldValidations: (v: UserTextFieldValidation) => void;
  dispatch: UserDispatch,
  allowedOAuthDomainsCheckProcessing: boolean;
  onLoginIDBlur: (v:string) => void
}

const UserTextFieldComp: React.FC<Props> = (props) => {
  const {
    userRequest,
    internalCase,
    setIsEmailValid,
    isEmailValid,
    emailDomainValidationProcessing,
    setEmailDomainValidationProcessing,
    emailDomainValidator,
    internalUserLoginIDValidation,
    externalUserLoginIDValidation,
    updatetoggler,
    setEmailDomainValidation,
    emailDomainValidation,
    textFieldValiations,
    setTextFieldValidations,
    userLoginIDFormatValidation,
    dispatch,
    allowedOAuthDomainsCheckProcessing,
    onLoginIDBlur
  } = props;

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === "businessPhone" || name === "userLoginID") {
      dispatch({ [name]: value });
      return;
    }

    if (name === "emailAddress") {
      if (isEmail(value) && !internalCase) {
        dispatch({ [name]: value });
        setIsEmailValid(false);
        setEmailDomainValidationProcessing(true);

        emailDomainValidator(value);
      } else if (isEmail(value) && internalCase) {
        dispatch({ [name]: value });
        setIsEmailValid(false);
      } else {
        dispatch({ [name]: value });
        setIsEmailValid(true);
        setEmailDomainValidation(false);
      }
      return;
    }

    if (name === "lastName" || name === "firstName") {
      const hasSpace = /\s/.test(value);
      if (hasSpace) {
        setTextFieldValidations({
          ...textFieldValiations,
          ...(name === "firstName" && { fName: true }),
          ...(name === "lastName" && { lName: true }),
        });
      } else {
        setTextFieldValidations({
          ...textFieldValiations,
          ...(name === "firstName" && { fName: false }),
          ...(name === "lastName" && { lName: false }),
        });
      }
      dispatch({ [name]: value });
      return;
    }
  };

  const domain = userRequest.isManagedByG2 && userRequest.external 
  ? "@genstarinsext.com" 
  : !userRequest.isManagedByG2 && !userRequest.external 
  ? "@generalstar.com" : "";

  const onLoginIDBlured = (e:any) => {
    const {value} =  e.target;
    onLoginIDBlur(value);
  }

  return (
    <>
      <Grid container mt={2} maxWidth="lg" display={"flex"} alignItems={"flex-start"}>
        <Grid
          xs={12}
          sm={12}
          md={domain ? 6 : 4}
          pr={2}
          item
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
        >
          <TextInput
            required
            value={userRequest.userLoginID}
            onChange={handleTextChange}
            name="userLoginID"
            label="User Login ID"
            onBlur={onLoginIDBlured}
            disabled={updatetoggler && userRequest.external}
            error={
              externalUserLoginIDValidation ||
              internalUserLoginIDValidation ||
              userLoginIDFormatValidation
            }
            helperText={
              userLoginIDFormatValidation
                ? "Invalid Login ID format"
                : externalUserLoginIDValidation
                ? "Login ID must end with @genstarinsext.com"
                : internalUserLoginIDValidation
                ? "Login ID must end with @generalstar.com/@genre.com/@gumc.com"
                : ""
            }
          />
           {allowedOAuthDomainsCheckProcessing ? (
            <Spinner sx={{ marginLeft: "6px", marginTop: "6px" }} size={22} />
          ) : null}
        <LoginIDAppendButton 
          userRequest={userRequest}
          domain={domain}
          updatetoggler={updatetoggler}
          dispatch={dispatch}
          />
        </Grid>
        <Grid xs={12} sm={12} md={domain ? 3 : 4} pr={2} item>
          <TextInput
            required
            value={userRequest.firstName}
            onChange={handleTextChange}
            name="firstName"
            label="First Name"
            helperText={
              textFieldValiations.fName ? "Spaces are not allowed" : ""
            }
            error={textFieldValiations.fName}
          />
        </Grid>
        <Grid xs={12} sm={12} md={domain ? 3 : 4} item>
          <TextInput
            required
            value={userRequest.lastName}
            onChange={handleTextChange}
            name="lastName"
            label="Last Name"
            helperText={
              textFieldValiations.lName ? "Spaces are not allowed" : ""
            }
            error={textFieldValiations.lName}
          />
        </Grid>
      </Grid>
      <Grid container mt={2} maxWidth="lg">
        <Grid xs={12} sm={12} md={8} item sx={{ display: "flex" }} pr={2}>
          <TextInput
            required
            value={userRequest.emailAddress}
            onChange={handleTextChange}
            name="emailAddress"
            label="Email Address"
            helperText={
              userRequest?.emailAddress?.length !== 0 &&
              !isEmailValid &&
              emailDomainValidation
                ? "Invalid Domain name"
                : userRequest?.emailAddress?.length !== 0 &&
                  isEmailValid &&
                  !emailDomainValidation
                ? "Email is invalid"
                : ""
            }
            error={
              (userRequest?.emailAddress?.length !== 0 &&
                !isEmailValid &&
                emailDomainValidation) ||
              (userRequest?.emailAddress?.length !== 0 &&
                isEmailValid &&
                !emailDomainValidation)
            }
          />
          {emailDomainValidationProcessing ? (
            <Spinner sx={{ marginLeft: "6px", marginTop: "6px" }} size={22} />
          ) : null}
        </Grid>
        <Grid xs={12} sm={12} md={4} item>
          <TextInput
            // required
            value={userRequest.businessPhone}
            onChange={handleTextChange}
            name="businessPhone"
            label="Phone Number"
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default UserTextFieldComp;
