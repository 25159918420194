import React from "react";
import { Grid, MenuItem, Typography } from "@mui/material";
import { MuiStyledSwitch as Switch } from "@genre/g2common-theme";
import {
  formatDateForGrid,
  SelectList,
  SummarySkeleton,
  TextInput,
} from "@genrecp/g2clientportal-common";
import { CashTrackerProps } from "../../CashTrackerUtils";
import { SelectChangeEvent } from "@mui/material";
import CurrencyInput,{CurrencyInputOnChangeValues} from 'react-currency-input-field';

interface Props extends CashTrackerProps {}

function SuspenseForm({ request, dispatch }: Props) {
  const label = { inputProps: { "aria-label": "Switch demo" } };

  function handleDropDownChange(event: SelectChangeEvent<unknown>) {
    const { name, value } = event.target;
    const val = value as string;
    dispatch({
      suspenseForm: {
        ...request.suspenseForm,
        [name]: val,
      },
    });
  }

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === "grossPremium" || name === "suspendedAmount") {
      const pointChecker = value.split(".");
      if (
        pointChecker.length === 1 ||
        (pointChecker.length === 2 && pointChecker[1].length <= 2)
      ) {
        dispatch({
          suspenseForm: {
            ...request.suspenseForm,
            [name]: value,
          },
        });
      }
      return;
    }

    if(name === "endtNumber"){
      const regex = /^[0-9]+$/gm;
      const check = regex.test(value);
      if(!check)
      return;
    }

    dispatch({
      suspenseForm: {
        ...request.suspenseForm,
        [name]: value,
      },
    });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      suspenseForm: {
        ...request.suspenseForm,
        suspenseTypeID: event.target.checked ? 1 : 2,
      },
    });
  };

  const handleCurrencyChange = (value: string | undefined, name?: string, values?: CurrencyInputOnChangeValues) => {
    dispatch({
      suspenseForm: {
        ...request.suspenseForm,
        [name!]: value,
      },
    });
  }

  return (
    <Grid container spacing={2} mb={2}>
      <Grid item md={4}>
        <TextInput
          label="Check Number"
          name="checkNumber"
          value={request.cashTrackerMasterFields.checkNumber}
          disabled
        />
      </Grid>
      <Grid item md={4}>
        <TextInput
          label="Check Date"
          name="checkDate"
          value={formatDateForGrid(request.cashTrackerMasterFields.checkDate)}
          disabled
        />
      </Grid>
      <Grid item md={4}>
        <TextInput
          label="Lock Box Date"
          name="lockBoxDate"
          value={formatDateForGrid(request.cashTrackerMasterFields.lockBoxDate)}
          disabled
        />
      </Grid>
      <Grid item md={4}>
        <TextInput
          label="Suspended to billpoint"
          name="billpoint"
          value={request.cashTrackerMasterFields.billpoint}
          disabled
        />
      </Grid>
      <Grid item md={4}>
        <TextInput
          label="Company"
          name="companyName"
          value={request.cashTrackerMasterFields.companyName}
          disabled
        />
      </Grid>
      <Grid item md={4}>
        <TextInput
          label="Branch"
          name="branch"
          value={request.cashTrackerMasterFields.branchName}
          disabled
        />
      </Grid>
      <Grid item md={4}>
        <TextInput
          label="Policy Number"
          name="policyNumber"
          value={request.suspenseForm.policyNumber}
          onChange={handleTextChange}
          required
          inputProps={{
            maxLength: 10,
          }}
        />
      </Grid>
      <Grid item md={4}>
        <TextInput
          label="Insured Name"
          name="insuredName"
          required
          value={request.suspenseForm.insuredName}
          onChange={handleTextChange}
          inputProps={{
            maxLength: 100,
          }}
        />
      </Grid>
      <Grid
        item
        md={4}
        gap={2}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <CurrencyInput
          name="suspendedAmount"
          id="suspendedAmount"
          required
          customInput={TextInput}
          value={request.suspenseForm.suspendedAmount}
          onValueChange={handleCurrencyChange}
          allowNegativeValue={false}
          decimalSeparator={"."}
          decimalScale={2}
          allowDecimals
          prefix="$"
        />
      </Grid>
      <Grid
        item
        md={4}
        gap={2}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <CurrencyInput
          name="grossPremium"
          id="grossPremium"
          required
          customInput={TextInput}
          value={request.suspenseForm.grossPremium}
          decimalSeparator={"."}
          decimalScale={2}
          onValueChange={handleCurrencyChange}
          allowNegativeValue={false}
          allowDecimals
          prefix="$"
        />
      </Grid>
      <Grid
        item
        md={4}
        gap={2}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <SummarySkeleton
          condition={request.suspenseForm.suspenseReasonsLoading}
        >
          <SelectList
            label="Suspense Reasons"
            value={request.suspenseForm.suspenseReason}
            name="suspenseReason"
            onChange={handleDropDownChange}
            allowempty={false}
            required
          >
            {request.suspenseForm.suspenseReasons.map((item, idx) => (
              <MenuItem key={idx} value={item.suspenseReasonTypeID}>
                {item.suspenseReasonTypeText}
              </MenuItem>
            ))}
          </SelectList>
        </SummarySkeleton>
      </Grid>
      <Grid
        item
        md={4}
        gap={2}
        display={"flex"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
      >
        <Grid
          xs={12}
          sm={10}
          md={3}
          display={"flex"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          gap={1}
          item
        >
          <Typography variant="labelMedium" fontWeight={"bold"}>
            Policy
          </Typography>
          <Switch
            {...label}
            name="policyEnd"
            test-id="policyEnd"
            checked={request.suspenseForm.suspenseTypeID === 2 ? false : true}
            onChange={handleInputChange}
          />
          <Typography variant="labelMedium" fontWeight={"bold"}>
            Endorsement
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        md={4}
        gap={2}
        display={"flex"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
      >
        <TextInput
          label="Endt Number"
          name="endtNumber"
          value={request.suspenseForm.endtNumber}
          onChange={handleTextChange}
          inputProps={{
            maxLength: 99,
          }}
        />
      </Grid>
      <Grid
        item
        md={8}
        gap={2}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <TextInput
          label="Additional Info"
          name="addInfo"
          value={request.suspenseForm.addInfo}
          onChange={handleTextChange}
          inputProps={{
            maxLength: 500,
          }}
          multiline
          rows={4}
        />
      </Grid>
    </Grid>
  );
}

export default SuspenseForm;
