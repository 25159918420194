import React, { useState } from "react";
import { Grid, MenuItem } from "@mui/material";
import {
  CustomSnackbar,
  handleErrorResponse,
  ModalErrors,
  SelectList,
  TextInput,
} from "@genrecp/g2clientportal-common";
import { SelectChangeEvent } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SummarySkeleton } from "@genrecp/g2clientportal-common";
import { CashTrackerProps, Client } from "../CashTrackerUtils";
import CurrencyInput, {
  CurrencyInputOnChangeValues,
} from "react-currency-input-field";
import Autocomplete from "@mui/material/Autocomplete";
import { delegatedAxios } from "../../../../services/axios";
import { ClientInfosResponseType } from "./cashTrackerFormUtils";
import { companies } from "../../admin/delegatedIssuesAdmin/clientCashDetails/ClientCashDetailsForm";

interface Props extends CashTrackerProps {
  modalErrors: ModalErrors;
  setModalErrors: (v: ModalErrors) => void;
  updateCashTrackerForm: boolean;
}

function CashTrackerForm({
  request,
  dispatch,
  modalErrors,
  setModalErrors,
}: Props) {
  const [inputValue, setInputValue] = useState<string>("");
  const [clientInfoTempID, setClientInfoTempID] = useState<string>("");

  function handleDropDownChange(event: SelectChangeEvent<unknown>) {
    const { name, value } = event.target;
    const val = value as string;
    dispatch({
      cashTrackerForm: {
        ...request.cashTrackerForm,
        [name]: val,
      },
    });
  }

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === "checkNumber") {
      const pattern = /[a-z0-9]+$/gi;
      let match = pattern.test(value);
      dispatch({
        cashTrackerForm: {
          ...request.cashTrackerForm,
          [name]: value,
          checkNumberValidation: !match,
        },
      });

      return;
    }

    dispatch({
      cashTrackerForm: {
        ...request.cashTrackerForm,
        [name]: value,
      },
    });
  };

  const handleDateChange = (
    value: string | null,
    keyboardInputValue?: string,
    name?: "checkDate" | "lockBoxDate"
  ) => {
    if (!name) return;
    dispatch({
      cashTrackerForm: {
        ...request.cashTrackerForm,
        [name]: value ? new Date(value).toISOString() : null,
      },
    });
  };

  const handleCurrencyChange = (
    value: string | undefined,
    name?: string,
    values?: CurrencyInputOnChangeValues
  ) => {
    dispatch({
      cashTrackerForm: {
        ...request.cashTrackerForm,
        [name!]: value,
      },
    });
  };

    React.useEffect(() => {
      clientInfoTempID && 
        getFormData(clientInfoTempID);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientInfoTempID]);

  function getFormData(clientInfoID: string) {
    if(!clientInfoID) return;
    dispatch({
      cashTrackerLoader: "CLIENT_INFO",
    });

    delegatedAxios
      .get(`/ClientInfos/Get/${clientInfoID}`)
      .then((res: any) => {
        dispatch({
          cashTrackerLoader: "",
        });
        const {
          billpointCode,
          branchName,
          g2CompanyCode,
          grapCode,
          mgAorBroker,
          operationsContact,
        } = (res?.data as ClientInfosResponseType) || {};

        if (res.status === 200) {
          dispatch({
            cashTrackerForm: {
              ...request.cashTrackerForm,
              mgAorBroker: mgAorBroker === "M" ? "MGA" : "BROKER",
              billpointCode: billpointCode || "",
              branchCode: branchName || "",
              g2CompanyCode: g2CompanyCode || "",
              bsdContact: operationsContact || "",
              grap: grapCode || "",
            },
          });
        }
      })
      .catch((e: any) => {
        dispatch({
          cashTrackerLoader: "",
        });

        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <CustomSnackbar request={modalErrors} setRequest={setModalErrors} />
      <Grid container spacing={2}>
        <Grid item md={6}>
          <SummarySkeleton
            condition={request.cashTrackerLoader === "CLIENT_NAME"}
          >
            <Autocomplete
              id="asynchronous-demo"
              test-id="autocomplete"
              sx={{ width: "100%" }}
              isOptionEqualToValue={(option, value) =>
                option.clientName === value.clientName
              }
              value={request.cashTrackerForm.clientInfo}
              getOptionLabel={(option: Client) => option.clientName}
              options={request.cashTrackerForm.clientNames}
              inputValue={inputValue}
              onInputChange={(_, newInputValue: string) => {
                setInputValue(newInputValue);
              }}
              onChange={(
                event: React.SyntheticEvent<Element, Event>,
                value: Client | null
              ) => {
                setClientInfoTempID(value ? value.clientInfoID : "");
                dispatch({
                  cashTrackerForm: {
                    ...request.cashTrackerForm,
                    clientInfo: value,
                  },
                });
              }}
              renderInput={(params: any) => (
                <TextInput
                  {...params}
                  size="small"
                  label="Client Name"
                  required
                />
              )}
            />
          </SummarySkeleton>
        </Grid>
        <Grid item md={6}>
          <SummarySkeleton
            condition={request.cashTrackerLoader === "CLIENT_INFO"}
          >
            <TextInput
              label="Type"
              name="mgAorBroker"
              value={request.cashTrackerForm.mgAorBroker}
              disabled
            />
          </SummarySkeleton>
        </Grid>
        <Grid item md={6}>
          <SummarySkeleton
            condition={request.cashTrackerLoader === "CLIENT_INFO"}
          >
            <TextInput
              label="Bill Point"
              name="billpointCode"
              value={request.cashTrackerForm.billpointCode}
              disabled
            />
          </SummarySkeleton>
        </Grid>
        <Grid item md={6}>
          <SummarySkeleton
            condition={request.cashTrackerLoader === "CLIENT_INFO"}
          >
            <TextInput
              label="Branch"
              name="branchCode"
              value={request.cashTrackerForm.branchCode}
              disabled
            />
          </SummarySkeleton>
        </Grid>
        <Grid item md={6}>
          <TextInput
            label="Check Number"
            name="checkNumber"
            value={request.cashTrackerForm.checkNumber}
            onChange={handleTextChange}
            inputProps={{
              maxLength: 50,
            }}
            required
            error={request.cashTrackerForm.checkNumberValidation}
            helperText={
              request.cashTrackerForm.checkNumberValidation
                ? "Check Number can only be alphanumeric"
                : ""
            }
          />
        </Grid>
        <Grid item md={6}>
          <CurrencyInput
            name="checkAmount"
            id="checkAmount"
            required
            value={request.cashTrackerForm.checkAmount}
            customInput={TextInput}
            decimalSeparator={"."}
            decimalScale={2}
            onValueChange={handleCurrencyChange}
            allowNegativeValue={false}
            allowDecimals
            prefix="$"
          />
        </Grid>
        <Grid md={6} item>
          <DatePicker
            value={request.cashTrackerForm.checkDate}
            label="Check Date"
            onChange={(value) =>
              handleDateChange(value, undefined, "checkDate")
            }
            renderInput={(props) => (
              <TextField size="small" required fullWidth {...props} />
            )}
          />
        </Grid>
        <Grid item md={6}>
          <SummarySkeleton
            condition={request.cashTrackerLoader === "CLIENT_INFO"}
          >
            <TextInput
              label="BSD Contact"
              name="bsdContact"
              value={request.cashTrackerForm.bsdContact}
              disabled
            />
          </SummarySkeleton>
        </Grid>

        <Grid item md={6}>
          <SummarySkeleton
            condition={request.cashTrackerLoader === "CLIENT_INFO"}
          >
            <TextInput
              label="Company"
              name="g2CompanyCode"
              value={companies.find(company => company.value === request.cashTrackerForm.g2CompanyCode)?.name || ""}
              disabled
            />
          </SummarySkeleton>
        </Grid>

        <Grid item md={6}>
          <SummarySkeleton
            condition={request.cashTrackerLoader === "CLIENT_INFO"}
          >
            <TextInput
              label="GRAP search"
              name="grap"
              value={request.cashTrackerForm.grap}
              disabled
            />
          </SummarySkeleton>
        </Grid>

        <Grid md={6} item>
          <DatePicker
            value={request.cashTrackerForm.lockBoxDate}
            label="Lock Box Date"
            onChange={(value) =>
              handleDateChange(value, undefined, "lockBoxDate")
            }
            renderInput={(props) => (
              <TextField size="small" required fullWidth {...props} />
            )}
          />
        </Grid>
        <Grid item md={6}>
          <SelectList
            label="Status"
            name="cashTrackerStatus"
            value={request.cashTrackerForm.cashTrackerStatus || "1"}
            onChange={handleDropDownChange}
            allowempty={false}
            required
            disabled
          >
            {request.cashTrackerForm.cashTrackerStatuses.map((item, idx) => (
              <MenuItem key={idx} value={item.cashIssueStatusTypeID}>
                {item.cashIssueStatusTypeText}
              </MenuItem>
            ))}
          </SelectList>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
}

export default CashTrackerForm;
